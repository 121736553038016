import {
  BrainCog,
  CloudUpload,
  Fingerprint,
  Lock,
  Workflow,
} from 'lucide-react'

import Heading from '~/core/ui/Heading'
import Divider from '~/core/ui/Divider'
import SubHeading from '~/core/ui/SubHeading'
import Container from '~/core/ui/Container'

import { motion } from 'framer-motion'

import Pill from '~/components/landingpage/Pill'
import BackedByInfoPill from '~/components/landingpage/BackedByInfoPill'
import FeatureItem from '~/components/landingpage/FeatureItem'
import MainCallToActionButton from '~/components/landingpage/MainCallToActionButton'
import { Hero } from '~/components/landingpage/Hero'
import WorkflowSection from '~/components/landingpage/WorkflowSection'
import AnimatedLinkArrow from '~/components/landingpage/AnimatedLinkArrow'
import Testimonials from '~/components/landingpage/Testimonials'
import FAQ from '~/components/landingpage/FAQ'
import BlurMaskPattern from '~/components/landingpage/BackgroundPattern'
import BackgroundPattern from '../components/landingpage/BackgroundPattern'
import MouseGradient from '~/components/landingpage/MouseGradient'

export default function Index() {
  return (
    <div className="flex flex-col space-y-16">
      <BackgroundPattern>
        <MouseGradient />
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-3/4 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className={
            'py-6 flex flex-col items-center md:flex-row'
            + ' mx-auto flex-1 justify-center animate-in fade-in '
            + ' duration-1000 slide-in-from-top-12'
          }
        >
          <div className="flex w-full flex-1 flex-col items-center space-y-2">
            <BackedByInfoPill href="https://www.joinfounders.co/portfolio" />
            <Hero />
          </div>
        </div>
        <Container>
          <WorkflowSection />
        </Container>

      </BackgroundPattern>

      <Container>
        <BackedBy />
      </Container>
      <Divider />

      <Container>
        <div className="flex flex-col items-center justify-center space-y-24 pb-16 pt-3">
          <div className="flex max-w-3xl flex-col items-center text-center">
            <div className="text-primary-600 font-medium">Faster Insights</div>
            <div className="flex flex-col space-y-2.5">
              <Heading type={1}>Everything you need</Heading>
              <Heading type={1}>for instant insights</Heading>

              <SubHeading as="h5">
                Transform complex data into clear, actionable insights in minutes. Our
                platform combines powerful AI tools into simple, automated workflows.
              </SubHeading>
            </div>
          </div>

          <div className="w-full max-w-6xl">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <FeatureItem
                icon={<CloudUpload className="size-4 md:size-5" />}
                title="Open-End Analysis"
                description="Process thousands of survey responses in minutes instead of days. Our AI engine automatically categorizes and analyzes open-ended responses with 95% accuracy, while giving you full control to edit and refine results."
              />
              <FeatureItem
                icon={<Lock className="size-4 md:size-5" />}
                title="Smart Transcription"
                description="Turn hours of interviews and focus groups into searchable insights in just 3 minutes. With 92% accuracy and chat-based transcript querying, you can quickly find and analyze key discussion points."
              />
              <FeatureItem
                icon={<BrainCog className="size-4 md:size-5" />}
                title="AI Researcher"
                description="Your personal research assistant that can scan thousands of sources in minutes. Generate comprehensive reports with cited sources, analyze competitors, or track emerging trends."
              />
              <FeatureItem
                icon={<Workflow className="size-4 md:size-5" />}
                title="Configurable Workflow Builder"
                description="Connect tools and automate your entire insights process. Build workflows once to collect data from any source, enrich it with AI analysis, and transform it into actionable insights - then run it whenever you need."
              />
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <Testimonials />
      </Container>

      <Container>
        <FAQ />
      </Container>

      <Container>
        <div className="flex flex-col items-center text-center px-4 py-16">
          <h2 className="text-4xl sm:text-5xl md:text-6xl font-heading font-medium dark:text-primary-50 text-gray-900 max-w-4xl">
            Start Generating Insights On Demand
          </h2>

          <p className="mt-6 text-lg text-gray-500 dark:text-gray-300 max-w-2xl">
            Join leading research teams already transforming their data into actionable insights in minutes.
          </p>

          <div className="mt-8 flex flex-col sm:flex-row items-center gap-4">
            <MainCallToActionButton
              href="/contact"
              text="Get Insights for Free"
            />

            <AnimatedLinkArrow
              href="/solutions/topic-analysis"
              text="Learn more"
              variant="gray"
              className="text-sm sm:text-lg xl:text-lg px-8 py-2.5 sm:py-4"
              transitionDelay={0.4}
            />
          </div>
        </div>
      </Container>
      <Divider />
    </div>

  )
}

function BackedBy() {
  return (
    <div className="py-12 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8">
          Trusted by teams in...
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {/* Light mode images */}
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/opinions-logo-black.png"
            alt="Opinions"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/emc-logo-black.png"
            alt="EMC"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/bordbia-logo-dark.png"
            alt="Bord Bia"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/amarach-logo-black.png"
            alt="Amarach"
            width={158}
            height={48}
          />

          {/* Dark mode images */}
          <img
            className="col-span-1 max-h-12 w-full object-contain hidden dark:block"
            src="/assets/images/opinions-logo-white.png"
            alt="Opinions"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain hidden dark:block"
            src="/assets/images/emc-logo-white.png"
            alt="EMC"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain hidden dark:block"
            src="/assets/images/bordbia-logo-white.png"
            alt="Bord Bia"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain hidden dark:block"
            src="/assets/images/amarach-logo-white.png"
            alt="Amarach"
            width={158}
            height={48}
          />
        </div>
        <div className="flex flex-col items-center pt-12 space-y-4 sm:space-y-0">
          {/* Mobile Layout */}
          <div className="sm:hidden w-full flex flex-col items-center">
            <Pill>
              <span className="flex items-center justify-center">
                <span className="whitespace-normal">
                  Amarach now launches twice as many surveys per week!
                </span>
              </span>
            </Pill>
            <div className="flex justify-center mt-4">
              <AnimatedLinkArrow
                text="Read our Case Study"
                href="/blog/case-study-amarach"
                variant="blue"
                className="px-1 text-sm"
                transitionDelay={0}
              />
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden sm:block">
            <Pill>
              <span className="flex items-center space-x-4 whitespace-nowrap dark:text-slate-800">
                <span>Amarach launches twice as many surveys per week thanks to Talio!</span>
                <AnimatedLinkArrow
                  text="Read our Case Study"
                  href="/blog/case-study-amarach"
                  variant="blue"
                  className="px-1 text-sm"
                  transitionDelay={0}
                />
              </span>
            </Pill>
          </div>
        </div>
      </div>
    </div>
  )
}
