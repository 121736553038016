import React from 'react'

const faqs = [
  {
    id: 1,
    question: 'What types of insights can I generate?',
    answer: 'From market research and customer feedback to competitor analysis and trend monitoring - if there\'s data, we can analyze it. Our AI tools work with surveys, focus groups, web data, and more.',
  },
  {
    id: 2,
    question: 'How does the workflow builder work?',
    answer: 'Simply tell our AI what insights you need, and it will build the workflow for you. You can also customize workflows by combining tools like web scraping, topic analysis, and AI agents to collect and analyze data automatically.',
  },
  {
    id: 3,
    question: 'How does your transcription service work?',
    answer: 'Our AI can join your live calls on Zoom, Google Meet, and Microsoft Teams, or process pre-recorded files. Simply invite our bot or upload your recordings and get searchable, analyzed transcripts in minutes.',
  },
  {
    id: 4,
    question: 'How customizable is the open-ended analysis?',
    answer: 'Very! You have complete editing control over categories, themes, and sentiment analysis. Export your results in multiple formats.',
  },
  {
    id: 5,
    question: 'Can I share insights with my team?',
    answer: 'Yes, easily share insights, reports, and workflows with team members. Enterprise plans include advanced collaboration features and user management.',
  },
  {
    id: 6,
    question: 'Is my data secure?',
    answer: 'We maintain enterprise-grade security with encryption at rest and in transit. For enterprise clients, we can accommodate custom security requirements.',
  },
]

export default function FAQ() {
  return (
    <div className="mx-4 divide-y divide-gray-900/10 dark:divide-gray-200/10 py-8 sm:py-12 lg:py-14">
      <h2 className="text-4xl font-semibold tracking-tight text-gray-900 dark:text-gray-200 sm:text-5xl">
        Frequently asked questions
      </h2>
      <dl className="mt-10 space-y-8 divide-y divide-gray-900/10 dark:divide-gray-200/10">
        {faqs.map(faq => (
          <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
            <dt className="text-base/7 font-semibold text-gray-900 dark:text-gray-200 lg:col-span-5">{faq.question}</dt>
            <dd className="mt-4 lg:col-span-7 lg:mt-0">
              <p className="text-base/7 text-gray-600 dark:text-gray-300">{faq.answer}</p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
