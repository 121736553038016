import { useEffect, useRef } from 'react'

export default function MouseGradient() {
  const gradientRef = useRef(null)
  const animationRef = useRef(null)
  const timeRef = useRef(0)

  useEffect(() => {
    const updateGradients = (mouseX, mouseY) => {
      if (!gradientRef.current)
        return

      const time = timeRef.current

      const float1X = 50 + Math.sin(time * 0.5) * Math.cos(time * 0.3) * 30
      const float1Y = 50 + Math.sin(time * 0.4) * 20

      const float2X = 50 + Math.cos(time * 0.3) * Math.sin(time * 0.5) * 30
      const float2Y = 50 + Math.cos(time * 0.4) * 20

      gradientRef.current.style.background = `
        radial-gradient(circle at ${mouseX}% ${mouseY}%, hsla(210, 100%, 85%, 0.2) 0%, transparent 35%),
        radial-gradient(circle at ${100 - mouseX}% ${mouseY}%, hsla(330, 100%, 85%, 0.2) 0%, transparent 35%),
        radial-gradient(circle at ${50}% ${100 - mouseY}%, hsla(90, 100%, 85%, 0.2) 0%, transparent 35%),
        radial-gradient(circle at ${float1X}% ${float1Y}%, hsla(150, 100%, 85%, 0.15) 0%, transparent 35%),
        radial-gradient(circle at ${float2X}% ${float2Y}%, hsla(270, 100%, 85%, 0.15) 0%, transparent 35%)
      `
    }

    const storeMousePosition = (e) => {
      if (!gradientRef.current)
        return

      const rect = gradientRef.current.getBoundingClientRect()
      const x = ((e.clientX - rect.left) / rect.width) * 100
      const y = ((e.clientY - rect.top) / rect.height) * 100
      const clampedX = Math.max(10, Math.min(90, x))
      const clampedY = Math.max(10, Math.min(90, y))

      gradientRef.current.dataset.mouseX = clampedX.toString()
      gradientRef.current.dataset.mouseY = clampedY.toString()
    }

    const animate = () => {
      timeRef.current += 0.01
      const currentX = Number.parseFloat(gradientRef.current?.dataset.mouseX ?? '50')
      const currentY = Number.parseFloat(gradientRef.current?.dataset.mouseY ?? '50')
      updateGradients(currentX, currentY)
      animationRef.current = requestAnimationFrame(animate)
    }

    window.addEventListener('mousemove', storeMousePosition)
    animationRef.current = requestAnimationFrame(animate)

    return () => {
      window.removeEventListener('mousemove', storeMousePosition)
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [])

  return (
    <div
      ref={gradientRef}
      className="absolute inset-0 -z-10 transition-[background] duration-300 ease-out rounded-3xl"
      data-mouse-x="50"
      data-mouse-y="50"
      style={{
        background: `
          radial-gradient(circle at 50% 50%, hsla(210, 100%, 85%, 0.2) 0%, transparent 35%),
          radial-gradient(circle at 50% 50%, hsla(330, 100%, 85%, 0.2) 0%, transparent 35%),
          radial-gradient(circle at 50% 50%, hsla(90, 100%, 85%, 0.2) 0%, transparent 35%),
          radial-gradient(circle at 50% 50%, hsla(150, 100%, 85%, 0.15) 0%, transparent 35%),
          radial-gradient(circle at 50% 50%, hsla(270, 100%, 85%, 0.15) 0%, transparent 35%)
        `,
      }}
    />
  )
}
