import React from 'react'
import { Link } from '@remix-run/react'
import FoundersIcon from './FoundersIcon'
import FoundersIconBlack from './FoundersIconBlack'
import Pill from './Pill'
import { useTheme } from '~/core/contexts/theme-context'
// import RocketShipTrinket from './RocketShipTrinket'

interface BackedByInfoPillProps {
  href: string
}

const BackedByInfoPill: React.FC<BackedByInfoPillProps> = ({ href }) => {
  const Icon = FoundersIconBlack

  return (
    <div className="relative">
      {/* <RocketShipTrinket /> */}
      <Link to={href} target="_blank" rel="noopener noreferrer">
        <Pill>
          <span className="flex items-center justify-between w-full">
            <span className="w-16"></span>
            {' '}
            <span className="flex items-center space-x-2 text-grey-600 dark:text-slate-800">
              <Icon />
              <span>Backed by Founders</span>
            </span>
            <span className="w-16"></span>
            {' '}
          </span>
        </Pill>
      </Link>
    </div>
  )
}

export default BackedByInfoPill
