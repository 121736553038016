import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

// titles to animate through
const titles = [
  'Market Researchers.',
  'Enterprise Teams.',
  'Academic Researchers.',
]

// Desktop version of the hero text
function DesktopHeroText({ currentIndex, isVisible }: { currentIndex: number, isVisible: boolean }) {
  return (
    <div className="hidden sm:block">
      <div className="whitespace-nowrap mb-4 sm:mb-6 text-slate-800 tracking-wide dark:text-gray-200">
        Insights on Demand for
      </div>
      <div className="sm:w-[500px] md:w-[620px] lg:w-[770px] xl:w-[1000px] overflow-hidden justify-center">
        <AnimatedText currentIndex={currentIndex} isVisible={isVisible} />
      </div>
    </div>
  )
}

// Mobile version of the hero text
function MobileHeroText({ currentIndex, isVisible }: { currentIndex: number, isVisible: boolean }) {
  return (
    <div className="block sm:hidden">
      <div className="whitespace-nowrap mb-4 text-slate-800 text-[8vw] tracking-wide">
        Insights on Demand for
      </div>
      <div className="w-full overflow-hidden">
        <div className="text-[8vw] leading-tight">
          <AnimatedText currentIndex={currentIndex} isVisible={isVisible} />
        </div>

      </div>
    </div>
  )
}

function AnimatedText({ currentIndex, isVisible }: { currentIndex: number, isVisible: boolean }) {
  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          key={currentIndex}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          className="relative"
        >
          <span className="relative inline-block min-h-[1.2em] whitespace-nowrap">
            <span className="bg-gradient-to-r from-pink-400 to-primary-600 text-transparent bg-clip-text">
              {titles[currentIndex]}
            </span>

            <motion.span
              className="absolute inset-0"
              animate={{
                backgroundPosition: ['100% 0', '-100% 0'],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: 'linear',
              }}
              style={{
                background: 'linear-gradient(110deg, transparent 45%, white 50%, transparent 55%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                backgroundSize: '200% 100%',
                color: 'transparent',
                mixBlendMode: 'soft-light',
              }}
            >
              {titles[currentIndex]}
            </motion.span>
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export function AnimatedHeroTitle() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    const animate = () => {
      setIsVisible(false)
      timeoutRef.current = setTimeout(() => {
        setCurrentIndex(prev => (prev + 1) % titles.length)
        setIsVisible(true)
      }, 500)
    }

    const interval = setInterval(animate, 3000)
    return () => {
      clearInterval(interval)
      if (timeoutRef.current)
        clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-heading font-medium px-4 sm:px-0">
      <MobileHeroText currentIndex={currentIndex} isVisible={isVisible} />
      <DesktopHeroText currentIndex={currentIndex} isVisible={isVisible} />
    </h1>
  )
}
