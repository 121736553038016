import { Menu } from '@headlessui/react'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import type { LucideIcon } from 'lucide-react'
import type { FC } from 'react'

interface MenuOption {
  icon: LucideIcon
  label: string
  onClick?: () => void
}

interface WorkflowDropdownButtonProps {
  options: MenuOption[]
}

const WorkflowDropdownButton: FC<WorkflowDropdownButtonProps> = ({
  options,
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0])

  return (
    <Menu as="div" className="inline-block text-left relative">
      <Menu.Button className="inline-flex items-center gap-3 rounded-lg bg-white px-4 py-2.5 text-gray-700 shadow-lg ring-1 ring-inset ring-gray-200 hover:bg-gray-50 transition-colors">
        <div className="flex items-center justify-center size-6 rounded-full bg-primary-100">
          <selectedOption.icon className="size-3.5 text-primary-500" />
        </div>

        <span className="text-sm font-semibold">{selectedOption.label}</span>

        <ChevronDown className="size-4 text-gray-400" />
      </Menu.Button>

      <div className="absolute left-1/2 right-auto -translate-x-1/2 min-w-[208px] z-50">
        <Menu.Items className="mt-2 origin-top rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="p-1">
            {options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setSelectedOption(option)
                      option.onClick?.()
                    }}
                    className={`${
                      active ? 'bg-gray-50' : ''
                    } group flex w-full items-center rounded-md px-3 py-2 text-sm`}
                  >
                    <option.icon
                      className={`mr-3 size-5 ${
                        selectedOption.label === option.label
                          ? 'text-primary-500'
                          : 'text-gray-400 group-hover:text-gray-500'
                      }`}
                    />
                    <span
                      className={
                        selectedOption.label === option.label
                          ? 'font-bold text-primary-500'
                          : 'text-gray-700'
                      }
                    >
                      {option.label}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </div>
    </Menu>
  )
}

export default WorkflowDropdownButton
