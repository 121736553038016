import React from 'react'
import { motion } from 'framer-motion'
import { AnimatedHeroTitle } from './AnimatedHeroTitle'
import MainCallToActionButton from './MainCallToActionButton'
import AnimatedLinkArrow from './AnimatedLinkArrow'

export function Hero() {
  return (
    <div className="flex flex-col items-center text-center px-4">
      <AnimatedHeroTitle />

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="mt-4 sm:mt-6 text-sm sm:text-base md:text-lg lg:text-xl text-gray-500 dark:text-gray-200 max-w-[280px] sm:max-w-lg md:max-w-xl lg:max-w-2xl mx-auto"
      >
        Transform complex data into clear, actionable insights with configurable AI tools.
      </motion.p>

      <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row items-center">
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
        >
          <MainCallToActionButton
            href="/contact"
            text="Get Insights for Free"
          />
        </motion.div>

        <AnimatedLinkArrow
          href="/solutions/topic-analysis"
          variant="gray"
          text="Learn more"
          className="text-sm sm:text-lg xl:text-lg px-8 py-2.5 sm:py-4"
          transitionDelay={0.4}
        />
      </div>
    </div>
  )
}

export default Hero
