import React, { useEffect, useState } from 'react'
import { Command } from 'lucide-react'
import { AnimatePresence, motion } from 'framer-motion'
import StatsExample from './StatsExample'

export default function WorkflowRun() {
  const text = 'run the workflow, generate a csv file and then chart it...'
  const characters = Array.from(text)
  const [displayedChars, setDisplayedChars] = useState<string[]>([])
  const [isComplete, setIsComplete] = useState(false)
  const [showStats, setShowStats] = useState(false)

  useEffect(() => {
    if (displayedChars.length === characters.length) {
      const timer = setTimeout(() => setIsComplete(true), 1000)
      return () => clearTimeout(timer)
    }

    const timer = setTimeout(() => {
      setDisplayedChars(prev => [...prev, characters[prev.length]])
    }, 50)

    return () => clearTimeout(timer)
  }, [displayedChars, characters])

  const handleRun = () => {
    setShowStats(true)
    window.scrollTo({
      top: window.scrollY + 200,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <div className="-z-1 flex items-center justify-between w-full max-w-2xl px-3 py-2.5 bg-white rounded-2xl shadow-xl ring-1 ring-inset ring-gray-200">
        <div className="flex items-center space-between gap-3">
          <div className="flex items-center justify-center size-8 rounded-md bg-gray-200">
            <Command className="size-5 text-gray-500" />
          </div>

          <div className="text-sm font-semibold text-slate-700/90">
            <span>{displayedChars.join('')}</span>
            {!isComplete && (
              <motion.span
                animate={{ opacity: [1, 0] }}
                transition={{
                  duration: 0.8,
                  repeat: Infinity,
                  repeatType: 'reverse',
                }}
                className="inline-block ml-px"
              >
                |
              </motion.span>
            )}
          </div>
        </div>

        <button
          onClick={handleRun}
          className="inline-flex items-center gap-1.5 px-4 py-2 text-sm font-semibold text-white bg-primary-500 rounded-lg hover:bg-primary-600 transition-colors"
        >
          Run
          <motion.span
            initial={{ x: -3 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 20,
            }}
          >
            →
          </motion.span>
        </button>
      </div>

      <AnimatePresence>
        {showStats && <StatsExample />}
      </AnimatePresence>
    </>
  )
}
