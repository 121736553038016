import React from 'react'

interface FeatureItemProps {
  icon: React.ReactNode
  title: string
  description: string
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, title, description }) => {
  return (
    <div className="flex items-start space-x-4 md:space-x-5">
      <div className="shrink-0">
        <FeatureIcon>{icon}</FeatureIcon>
      </div>
      <div className="flex flex-col space-y-3 pr-4 md:pr-8 lg:pr-12">
        <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
          {title}
        </h4>
        <div className="text-sm text-gray-600 dark:text-gray-400 max-w-md">
          {description}
        </div>
      </div>
    </div>
  )
}

export default FeatureItem

function FeatureIcon(props: React.PropsWithChildren) {
  return (
    <div className="flex rounded-lg shadow-lg hover:shadow-sky-200 dark:hover:shadow-sky-900">
      <div className="rounded-lg bg-primary-500 p-2.5 md:p-3 dark:ring-1 dark:ring-inset dark:ring-primary-200">
        <div className="text-white">
          {props.children}
        </div>
      </div>
    </div>
  )
}
