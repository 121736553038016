import React from 'react'
import Container from '~/core/ui/Container'
import WorkflowDropdownButton from './WorkflowDropdownButton'
import WorkflowRun from './WorkflowRun'
import { motion } from 'framer-motion'
import {
  AreaChart,
  BookCopy,
  Brain,
  Building2,
  Code,
  Database,
  FileSpreadsheet,
  FileText,
  Mic,
  Microscope,
  PanelRightOpen,
  Search,
  Users,
} from 'lucide-react'

function AnimatedGradientLine({ vertical = false }) {
  const gradientAnimation = {
    initial: { backgroundPosition: vertical ? '0% 200%' : '200% 50%' },
    animate: {
      backgroundPosition: vertical ? '0% 0%' : '0% 50%',
      transition: {
        duration: 3,
        ease: 'linear',
        repeat: Infinity,
      },
    },
  }

  return (
    <motion.div
      className={`${
        vertical ? 'w-0.5 h-full' : 'h-0.5 w-full'
      } rounded-full opacity-60`}
      style={{
        backgroundSize: '200% 200%',
        backgroundImage: `linear-gradient(${
          vertical ? 'to bottom' : 'to right'
        }, rgb(244, 114, 182), rgb(2, 132, 199), rgb(244, 114, 182))`,
      }}
      initial="initial"
      animate="animate"
      variants={gradientAnimation}
    />
  )
}

export default function WorkflowSection() {
  const dataSourceOptions = [
    { icon: Database, label: 'Import Data' },
    { icon: Search, label: 'Search' },
    { icon: BookCopy, label: 'Research Papers' },
    { icon: Building2, label: 'Companies' },
    { icon: Users, label: 'People' },
  ]

  const analysisOptions = [
    { icon: Brain, label: 'Sentiment Analysis' },
    { icon: Code, label: 'Coding' },
    { icon: Microscope, label: 'Research Analysis' },
    { icon: Mic, label: 'Transcription' },
  ]

  const outputOptions = [
    { icon: AreaChart, label: 'Dynamic Charting' },
    { icon: FileSpreadsheet, label: 'Create Spreadsheet' },
    { icon: FileText, label: 'Research Document' },
    { icon: PanelRightOpen, label: 'Augmented Survey' },
  ]

  return (
    <Container>
      <div className="pt-4 pb-20">
        <div className="flex flex-col items-center space-y-12">
          {/* Mobile layout (vertical) */}
          <div className="flex flex-col items-center space-y-8 md:hidden">
            <div className="pb-8">
              <WorkflowDropdownButton options={dataSourceOptions} />
              <div className="absolute left-1/2 translate-y-1/2 h-8 -z-10">
                <AnimatedGradientLine vertical />
              </div>
            </div>

            <div className="pb-8">
              <WorkflowDropdownButton options={analysisOptions} />
              <div className="absolute left-1/2 translate-y-1/2 h-8 -z-10">
                <AnimatedGradientLine vertical />
              </div>
            </div>

            <div className="pb-4">
              <WorkflowDropdownButton options={outputOptions} />
            </div>
          </div>

          {/* Desktop layout (horizontal) */}
          <div className="hidden md:flex items-center">
            <div className="relative">
              <WorkflowDropdownButton options={dataSourceOptions} />
              <div className="absolute top-1/2 left-[calc(100%+8px)] -translate-y-1/2 w-8 -z-10">
                <AnimatedGradientLine />
              </div>
            </div>

            <div className="mx-12 relative">
              <WorkflowDropdownButton options={analysisOptions} />
              <div className="absolute top-1/2 left-[calc(100%+8px)] -translate-y-1/2 w-8 -z-10">
                <AnimatedGradientLine />
              </div>
            </div>

            <div className="relative">
              <WorkflowDropdownButton options={outputOptions} />
            </div>
          </div>

          <WorkflowRun />
        </div>
      </div>
    </Container>
  )
}
