import React from 'react'

export default function Testimonials() {
  return (
    <section className="py-2 sm:py-4">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
            <div className="h-20 flex items-center">
              {' '}
              <img
                alt="Bord Bia Logo"
                src="/assets/images/bordbia-logo-dark.png"
                className="h-12 self-start dark:hidden"
              />
              <img
                src="/assets/images/bordbia-logo-white.png"
                alt="Bord Bia Logo"
                className="h-12 self-start hidden dark:block"
              />
            </div>
            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-lg/8 text-gray-900 dark:text-gray-100">
                <p className="italic">
                  "Talio - a saviour"
                </p>
              </blockquote>
              <figcaption className="mt-10 flex items-center gap-x-6">
                <img
                  alt="Barbara O'Donoghue"
                  src="/assets/images/barbara-o-donoghue.jpg"
                  className="size-14 rounded-full object-cover"
                />
                <div className="text-base">
                  <div className="font-semibold text-gray-900 dark:text-gray-100">Barbara O'Donoghue</div>
                  <div className="mt-1 text-gray-500 dark:text-gray-400">Client Insight @ Bord Bia</div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="flex flex-col border-t border-gray-900/10 dark:border-gray-100 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
            <div className="h-20">
              <img
                alt="Amarach Research Logo"
                src="/assets/images/amarach-logo-black.png"
                className="h-14 self-start dark:hidden"
              />
              <img
                src="/assets/images/amarach-logo-white.png"
                alt="Amarach Research Logo"
                className="h-14 self-start hidden dark:block"
              />
            </div>
            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-lg/8 text-gray-900 dark:text-gray-100">
                <p className="italic">
                  "The insight is second to none"
                </p>
              </blockquote>
              <figcaption className="mt-10 flex items-center gap-x-6">
                <img
                  alt="Gerard O'Neill"
                  src="/assets/images/gerard-o-neill.jpg"
                  className="size-14 rounded-full object-cover"
                />
                <div className="text-base">
                  <div className="font-semibold text-gray-900 dark:text-gray-100">Gerard O'Neill</div>
                  <div className="mt-1 text-gray-500 dark:text-gray-400">Chairman @ Amarach Research</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}
