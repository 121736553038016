import { motion } from 'framer-motion'
import React from 'react'

interface AnimatedLinkArrowProps {
  /** The URL the button should link to */
  href: string
  /** Optional custom text (defaults to "Learn more") */
  text?: string
  /** Optional className to override default styles */
  className?: string
  /** Color variant */
  variant?: 'gray' | 'blue'
  /** Animation delay in seconds */
  transitionDelay?: number
}

export default function AnimatedLinkArrow({
  href,
  text = 'Learn more',
  className,
  variant = 'gray',
  transitionDelay = 0.4,
}: AnimatedLinkArrowProps) {
  const styles = {
    gray: {
      text: 'text-gray-600 dark:text-gray-100',
      hover: 'hover:text-primary-500 dark:hover:text-primary-400',
      underline: 'bg-primary-500 dark:bg-primary-400',
    },
    blue: {
      text: 'text-primary-500 dark:text-primary-400',
      hover: 'hover:text-primary-600 dark:hover:text-primary-500',
      underline: 'bg-primary-500 dark:bg-primary-400',
    },
  }

  const currentStyle = styles[variant]

  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: transitionDelay }}
    >
      <a
        href={href}
        className={`
          group inline-flex items-center transition-colors relative
          ${currentStyle.text} ${currentStyle.hover}
          ${className || ''}
        `}
      >
        <span className="relative whitespace-nowrap">
          {text}
          {' '}
          →
          <span
            className={`absolute inset-x-0 bottom-0 h-0.5 ${currentStyle.underline} scale-x-0
              transition-transform duration-300 origin-left group-hover:scale-x-100`}
          />
        </span>
      </a>
    </motion.div>
  )
}
